import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CareerPage = () => {
  return (
    <Layout>
      <Seo
        title={"Careers"}
        description={
          "Interested in finding a career that provides growth, a supportive team environment, and more? See what open positions are available at EnerBank USA!"
        }
      />
      {/* section-gap */}
      <section className="career-info-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                EnerBank USA is now a part of the Regions Bank family. We bring
                over two decades of exclusive experience in home improvement
                lending, delivering the best financial products and services for
                home improvement professionals and homeowners. Our customers
                work with us because our people understand the home improvement
                business, and our products and services are designed to align
                with the needs of homeowners and home-improvement contractors.
                Our roots run deep and our future is bright -- join our team
                today as we strive to be the premier lender to homeowners!
              </p>

              <p>
                To view and apply for our open positions, visit the Regions
                careers page by clicking the link below or by typing&nbsp;
                <a
                  href="https://careers.regions.com/us/en/c/enerbank-jobs"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://careers.regions.com/us/en/c/enerbank-jobs
                </a>
                &nbsp;into your browser.
              </p>

              <p className="text-center">
                <a
                  className="btn btn-primary f-bold"
                  href="https://careers.regions.com/us/en/c/enerbank-jobs"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  View Open Positions
                </a>
              </p>

              <p className="text-center">
                <img
                  className="d-inline-block"
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/TopPlacesToWork_SocialMediaPost_500x500_v2-copy-1.jpg"
                  alt="EnerBank - Top places to work 2021"
                />
              </p>
            </div>
          </div>
        </div>
        {/* ends .container */}
      </section>
    </Layout>
  )
}
export default CareerPage

// careerPost {
//   edges {
//     node {
//       id
//       title
//       slug
//       careers {
//         availablePositions {
//           fieldGroupName
//           location
//           overview
//           positionTitle
//         }
//       }
//     }
//   }
// }

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        video_section {
          video {
            videoUrl
            videoBanner {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

// careerplural {
//   edges {
//     node {
//       careerPost {
//         edges {
//           node {
//             id
//             title
//             careers {
//               availablePositions {
//                 fieldGroupName
//                 location
//                 positionTitle
//                 viewJobDetail {
//                   target
//                   title
//                   url
//                 }
//               }
//             }
//             excerpt
//             slug
//           }
//         }
//       }
//     }
//   }
// }
